import { useState, useEffect } from "react";
import * as deviceAPI from "../lib/device";

// NOTE 안쓰고있음
function useConnection(deviceID: string) {
  const [connection, setConnection] = useState(false);

  const getConnection = () =>
    deviceAPI.getConnection(deviceID).then((resolvedData) => {
      setConnection(resolvedData);
    });

  useEffect(() => {
    getConnection();

    const connectionInterval = setInterval(getConnection, 1000 * 60);

    return () => {
      clearInterval(connectionInterval);
    };
    // eslint-disable-next-line
  }, []);

  return connection;
}

export default useConnection;
