import IoTAPI from "./api/IoTAPI";

const get = (deviceID: string) => IoTAPI.get("/device/" + deviceID);

export const getConnection = async (deviceID: string) => {
  try {
    const response = await get(deviceID);

    if (response.status !== 200) {
      return false;
    } else {
      return response.data.connection;
    }
  } catch (e) {
    return false;
  }
};

export const getUpdatedAt = async (deviceID: string) => {
  try {
    const response = await get(deviceID);

    if (response.status !== 200) {
      return {
        connection: false,
        updatedAt: "에러",
      };
    } else {
      const { connection, updatedAt } = response.data;
      return {
        connection: connection,
        updatedAt: updatedAt,
      };
    }
  } catch (e) {
    return {
      connection: false,
      updatedAt: "에러",
    };
  }
};

const patch = (deviceID: string, requestBody: Object) =>
  IoTAPI.patch("/device/" + deviceID, requestBody);

export const patchFeedMakerPower = async (deviceID: string, power: boolean) => {
  try {
    const response = await patch(deviceID, {
      property: { feedMaker: { power: power } },
    });

    if (response.status !== 200) {
      alert("요청에 실패하였습니다.");
    } else {
      alert("요청에 성공하였습니다.");
    }
  } catch (e) {
    alert("요청에 실패하였습니다.");
  }
};

export const patchAuto = async (deviceID: string, auto: boolean) => {
  try {
    const response = await patch(deviceID, {
      property: { waterLevel: { 0: { auto: auto } } },
    });

    if (response.status !== 200) {
      alert("요청에 실패하였습니다.");
    } else {
      alert("요청에 성공하였습니다.");
    }
  } catch (e) {
    alert("요청에 실패하였습니다.");
  }
};

export const patchPower = async (
  deviceID: string,
  outletName: number,
  power: boolean
) => {
  try {
    const response = await patch(deviceID, {
      property: { outlet: { [outletName]: { power: power } } },
    });

    if (response.status !== 200) {
      alert("요청에 실패하였습니다.");
    } else {
      alert("요청에 성공하였습니다.");
    }
  } catch (e) {
    alert("요청에 실패하였습니다.");
  }
};

export const patchAirConditioner = async (
  deviceID: string,
  propertyName: string,
  propertyValue: number | boolean
) => {
  try {
    const response = await patch(deviceID, {
      property: { airControlStatus: { [propertyName]: propertyValue } },
    });

    if (response.status !== 200) {
      // alert("요청에 실패하였습니다.");
    } else {
      // alert("요청에 성공하였습니다.");
    }
  } catch (e) {
    // alert("요청에 실패하였습니다.");
  }
};

// export const patchTimerInterval = async (
//   deviceID: string,
//   outletName: number,
//   interval: number
// ) => {
//   try {
//     const response = await patch(deviceID, {
//       property: { outlet: { [outletName]: { timerInterval: interval } } },
//     });

//     if (response.status !== 200) {
//       // success
//     } else {
//       // Fail
//     }
//   } catch (e) {
//     // Fail
//   }
// };

// export const patchOnOffInterval = async (
//   deviceID: string,
//   outletName: number,
//   interval: number
// ) => {
//   try {
//     const response = await patch(deviceID, {
//       property: { outlet: { [outletName]: { onOffInterval: interval } } },
//     });

//     if (response.status !== 200) {
//       // success
//     } else {
//       // Fail
//     }
//   } catch (e) {
//     // Fail
//   }
// };

export const patchInterval = async (
  deviceID: string,
  outletName: number,
  timerInterval: number,
  onOffInterval: number
) => {
  try {
    const response = await patch(deviceID, {
      property: {
        outlet: {
          [outletName]: {
            timerInterval: timerInterval,
            onOffInterval: onOffInterval,
          },
        },
      },
    });

    if (response.status !== 200) {
      alert("요청에 실패하였습니다.");
    } else {
      alert("요청에 성공하였습니다.");
    }
  } catch (e) {
    alert("요청에 실패하였습니다.");
  }
};
