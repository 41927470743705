import { useState, useEffect } from "react";
import * as feedMakerAPI from "../lib/feedMaker";
import * as deviceAPI from "../lib/device";

function useFeedMaker(deviceID: string) {
  const [power, setPower] = useState(false);

  const getPower = () => {
    feedMakerAPI.getPower(deviceID).then((resolvedData) => {
      setPower(resolvedData);
    });
  };

  const patchPower = () => {
    const newState = !power;
    deviceAPI.patchFeedMakerPower(deviceID, newState).then(() => {
      getPower();
    });
  };

  useEffect(() => {
    getPower();

    const powerInterval = setInterval(getPower, 1000 * 60);

    return () => {
      clearInterval(powerInterval);
    };

    // eslint-disable-next-line
  }, []);

  return { power, patchPower };
}

export default useFeedMaker;
