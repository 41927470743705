import IoTAPI from "./api/IoTAPI";

const get = (deviceID: string) => IoTAPI.get("/feedmaker/" + deviceID);

export const getPower = async (deviceID: string) => {
  try {
    const response = await get(deviceID);

    if (response.status !== 200) {
      return false;
    } else {
      return response.data.power;
    }
  } catch (e) {
    return false;
  }
};
