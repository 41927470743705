export type DeviceProps = {
  name: string;
  id: string;
};

export const nutrientControllers: DeviceProps[] = [
  { name: "1번 양액기", id: "NAB400-0001" },
];

export const cultivators: DeviceProps[] = [];

export const envs: DeviceProps[] = [
  { name: "1번 환경 측정기", id: "ENV100-0030" },
];

export const acs: DeviceProps[] = [];

export const smartFarmID = 7;
