import React from "react";
import styled from "styled-components";
import connected from "../../../images/connected.png";
import disconnected from "../../../images/disconnected.png";

const DeviceStatusBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
`;

const StyledImg = styled.img`
  width: 10%;
  height: auto;
`;

const DeviceName = styled.div`
  padding-left: 0.5rem;
  font-size: x-large;

  @media ${(props) => props.theme.mobile} {
    font-size: large;
  }
`;

interface DeviceStatusProps {
  children?: React.ReactNode;
  connection?: boolean;
}
function DeviceStatus({ children, connection = false }: DeviceStatusProps) {
  return (
    <DeviceStatusBlock>
      {connection ? (
        <StyledImg src={connected} alt="연결 됨" />
      ) : (
        <StyledImg src={disconnected} alt="연결 안 됨" />
      )}
      <DeviceName>{children}</DeviceName>
    </DeviceStatusBlock>
  );
}

export default DeviceStatus;
