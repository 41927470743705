import { useState, useEffect } from "react";
import * as waterLevelAPI from "../lib/waterlevel";
import { DeviceProps } from "../data/deviceIDs";

type WaterLevelProps = {
  name: string;
  waterLevel: number;
};

function useWaterLevels(deviceIDs: DeviceProps[]) {
  const [waterLevels, setWaterLevels] = useState<WaterLevelProps[]>([]);

  const promiseElement = (
    deviceID: DeviceProps,
    waterLevelName: number,
    waterLevels: WaterLevelProps[]
  ) => {
    if (deviceID.id.slice(0, 6) === "NAB400") {
      return waterLevelAPI
        .getWaterLevel(deviceID.id, waterLevelName)
        .then((resolvedData) => {
          switch (waterLevelName) {
            case 0:
              waterLevels.push({
                name: deviceID.name + "-양액",
                waterLevel: resolvedData,
              });
              break;
            case 1:
              waterLevels.push({
                name: deviceID.name + "-A제",
                waterLevel: resolvedData,
              });
              break;
            case 2:
              waterLevels.push({
                name: deviceID.name + "-B제",
                waterLevel: resolvedData,
              });
              break;
            default:
              waterLevels.push({
                name: deviceID.name + "-에러",
                waterLevel: resolvedData,
              });
              break;
          }
        });
    } else {
      return waterLevelAPI
        .getWaterLevel(deviceID.id, waterLevelName)
        .then((resolvedData) => {
          waterLevels.push({ name: deviceID.name, waterLevel: resolvedData });
        });
    }
  };

  const getWaterLevels = () => {
    let promiseArr: Promise<any>[] = [];
    let tmp: WaterLevelProps[] = [];
    deviceIDs.forEach((deviceID) => {
      if (deviceID.id.slice(0, 6) === "NAB400") {
        promiseArr.push(promiseElement(deviceID, 0, tmp));
        promiseArr.push(promiseElement(deviceID, 1, tmp));
        promiseArr.push(promiseElement(deviceID, 2, tmp));
      } else {
        promiseArr.push(promiseElement(deviceID, 0, tmp));
      }
    });

    Promise.all(promiseArr).then(() => {
      let result: WaterLevelProps[] = [];

      deviceIDs.forEach((deviceID) => {
        let arr: WaterLevelProps[] = [];

        tmp.forEach((element) => {
          if (element.name.slice(0, 6) === deviceID.name) {
            let category = element.name.slice(-2);
            switch (category) {
              // NAB300
              case "양액":
                arr[0] = element;
                break;
              case "A제":
                arr[1] = element;
                break;
              case "B제":
                arr[2] = element;
                break;
              // OA100
              // default:
              //   arr[0] = element;
              //   break;
            }
          } else if (element.name.slice(0, 10) === deviceID.name) {
            arr[0] = element;
          }
        });

        result = result.concat(arr);
      });

      setWaterLevels(result);
    });
  };

  useEffect(() => {
    getWaterLevels();

    const interval = setInterval(getWaterLevels, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  return waterLevels;
}

export default useWaterLevels;
