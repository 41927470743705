import IoTAPI from "./api/IoTAPI";

const get = (deviceID: string, outletName: number) =>
  IoTAPI.get("/outlet/" + deviceID + "/outlet-" + outletName);

export const getAuto = async (deviceID: string, outletName: number) => {
  try {
    const response = await get(deviceID, outletName);

    if (response.status !== 200) {
      return false;
    } else {
      return response.data.waterLevel.auto;
    }
  } catch (e) {
    return false;
  }
};

export const getPower = async (deviceID: string, outletName: number) => {
  try {
    const response = await get(deviceID, outletName);

    if (response.status !== 200) {
      return false;
    } else {
      return response.data.power;
    }
  } catch (e) {
    return false;
  }
};

// export const getTimerInterval = async (
//   deviceID: string,
//   outletName: number
// ) => {
//   try {
//     const response = await get(deviceID, outletName);

//     if (response.status !== 200) {
//       return 0;
//     } else {
//       const { timerInterval } = response.data;

//       return timerInterval;
//     }
//   } catch (e) {
//     return 0;
//   }
// };

// export const getOnOffInterval = async (
//   deviceID: string,
//   outletName: number
// ) => {
//   try {
//     const response = await get(deviceID, outletName);

//     if (response.status !== 200) {
//       return 0;
//     } else {
//       const { onOffInterval } = response.data;

//       return onOffInterval;
//     }
//   } catch (e) {
//     return 0;
//   }
// };

export const getInterval = async (deviceID: string, outletName: number) => {
  try {
    const response = await get(deviceID, outletName);

    if (response.status !== 200) {
      return { timerInterval: 0, onOffInterval: 0 };
    } else {
      const { timerInterval, onOffInterval } = response.data;

      return { timerInterval: timerInterval, onOffInterval: onOffInterval };
    }
  } catch (e) {
    return { timerInterval: 0, onOffInterval: 0 };
  }
};
