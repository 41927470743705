import { useState, useEffect } from "react";
import * as outletAPI from "../lib/outlet";
import * as deviceAPI from "../lib/device";

function useOutletPower(deviceID: string, outletName: number) {
  const [outletPower, setOutletPower] = useState(false);

  const getPower = () => {
    outletAPI.getPower(deviceID, outletName).then((resolvedData) => {
      setOutletPower(resolvedData);
    });
  };

  const patchOutletPower = () => {
    const newState = !outletPower;

    deviceAPI.patchPower(deviceID, outletName, newState).then(() => {
      getPower();
    });
  };

  useEffect(() => {
    getPower();

    const powerInterval = setInterval(getPower, 1000 * 60);

    return () => {
      clearInterval(powerInterval);
    };
    // eslint-disable-next-line
  }, []);

  return { outletPower, patchOutletPower };
}

export default useOutletPower;
