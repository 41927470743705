import React from "react";
import styled from "styled-components";
import TableData from "../../commons/TableData";
import Button from "../../commons/Button";
import { DeviceProps } from "../../data/deviceIDs";
import useConnection from "../../hooks/useConnection";
import useFeedMaker from "../../hooks/useFeedMaker";
import useOutletPower from "../../hooks/useOutletPower";
import useInterval from "../../hooks/useInterval";

const ControlGroupBlock = styled.div`
  width: 100%;
  height: 100%;
`;

const Contents = styled.div`
  width: 100%;
  height: 85%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;

  @media ${(props) => props.theme.mobile} {
    height: 75%;
  }
`;

const SupplyDiv = styled.div`
  width: 50%;
  height: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  @media ${(props) => props.theme.mobile} {
    width: 100%;
    justify-content: space-between;
  }
`;

const SupplyText = styled.text`
  font-weight: lighter;
`;

const SupplyButton = styled(Button)`
  width: 40%;
  height: 80%;

  @media ${(props) => props.theme.mobile} {
    padding: 0.5rem;
    font-size: 1.1rem;
  }
`;

const TimerDiv = styled.div`
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  @media ${(props) => props.theme.mobile} {
  }
`;

const TimerText = styled.text`
  font-weight: lighter;

  @media ${(props) => props.theme.mobile} {
    width: 35%;
  }
`;

const SettingDiv = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @media ${(props) => props.theme.mobile} {
    flex-direction: column;
  }
`;

const InputDiv = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${(props) => props.theme.mobile} {
    width: 100%;
    flex-direction: column;
    margin-top: 0.6rem;
  }
`;

const InputTitle = styled.text`
  width: 100%;
  text-align: left;
  font-size: small;

  @media ${(props) => props.theme.mobile} {
  }
`;

const InputBlockDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @media ${(props) => props.theme.mobile} {
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const Input = styled.input`
  width: 60%;
  outline: none;
  border: none;
  border-bottom: 2px solid #d9d9d9;
  font-size: 1.1rem;

  @media ${(props) => props.theme.mobile} {
    width: 40%;
  }
`;

const DetailText = styled.text`
  width: 40%;
`;

const SaveButton = styled(Button)`
  width: 10%;
  height: 50%;

  @media ${(props) => props.theme.mobile} {
    width: 25%;
    height: 80%;
    padding: 0.5rem;
    font-size: 1.2rem;
  }
`;

interface ControlGroupProps {
  deviceID: DeviceProps;
}

function ControlGroup({ deviceID }: ControlGroupProps) {
  const connection = useConnection(deviceID.id);
  const { power } = useFeedMaker(deviceID.id);
  const { outletPower, patchOutletPower } = useOutletPower(deviceID.id, 4);
  const {
    timerInterval,
    setTimerInterval,
    onOffInterval,
    setOnOffInterval,
    patchInterval,
  } = useInterval(deviceID.id, 4);

  const onSupplyClick = () => {
    if (
      window.confirm(
        outletPower
          ? "수동 급액을 중단하시겠습니까 ?"
          : "수동 급액을 시작하시겠습니까 ?"
      )
    ) {
      patchOutletPower();
    }
  };

  const timerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTimerInterval(Number(e.target.value));
  };

  const onOffChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOnOffInterval(Number(e.target.value));
  };

  const onSaveClick = () => {
    if (window.confirm("저장하시겠습니까 ?")) {
      patchInterval(timerInterval, onOffInterval);
    }
  };

  return (
    <ControlGroupBlock>
      <TableData title="양액기 제어">
        <Contents>
          <SupplyDiv>
            <SupplyText>수동 급액 제어</SupplyText>
            <SupplyButton
              connection={connection}
              power={outletPower}
              disabled={!power}
              onClick={onSupplyClick}
            >
              급액 시작
            </SupplyButton>
          </SupplyDiv>
          <TimerDiv>
            <TimerText>자동 급액 설정</TimerText>
            <SettingDiv>
              <InputDiv>
                <InputTitle>관수 주기</InputTitle>
                <InputBlockDiv>
                  <Input
                    type="number"
                    value={timerInterval}
                    onChange={timerChange}
                  />
                  <DetailText>초 마다</DetailText>
                </InputBlockDiv>
              </InputDiv>
              <InputDiv>
                <InputTitle>급액 시간</InputTitle>
                <InputBlockDiv>
                  <Input
                    type="number"
                    value={onOffInterval}
                    onChange={onOffChange}
                  />
                  <DetailText>초 동안</DetailText>
                </InputBlockDiv>
              </InputDiv>
            </SettingDiv>
            <SaveButton connection={connection} onClick={onSaveClick}>
              저장
            </SaveButton>
          </TimerDiv>
        </Contents>
      </TableData>
    </ControlGroupBlock>
  );
}

export default ControlGroup;
