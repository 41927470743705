import { useState, useEffect } from "react";
import * as deviceAPI from "../lib/device";
import * as outletAPI from "../lib/outlet";

function useInterval(deviceID: string, outletName: number) {
  const [timerInterval, setTimerInterval] = useState(0);
  const [onOffInterval, setOnOffInterval] = useState(0);

  const getInterval = () => {
    outletAPI.getInterval(deviceID, outletName).then((resolvedData) => {
      const {
        timerInterval,
        onOffInterval,
      }: { timerInterval: number; onOffInterval: number } = resolvedData;

      setTimerInterval(timerInterval);
      setOnOffInterval(onOffInterval);
    });
  };

  const patchInterval = (timerInterval: number, onOffInterval: number) => {
    let tmpTimerInterval = timerInterval;
    let tmpOnOffInterval = onOffInterval;

    if (tmpTimerInterval < 600 || tmpOnOffInterval < 10) {
      getInterval();
      alert(
        "관수 주기는 최소 10분, 급액 시간은 최소 10초로 설정되어야 합니다 !"
      );
    } else {
      deviceAPI
        .patchInterval(deviceID, outletName, tmpTimerInterval, tmpOnOffInterval)
        .then(() => {
          setTimeout(getInterval, 1000 * 1);
        });
    }
  };

  useEffect(() => {
    getInterval();

    const interval = setInterval(getInterval, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  return {
    timerInterval,
    setTimerInterval,
    onOffInterval,
    setOnOffInterval,
    patchInterval,
  };
}

export default useInterval;
